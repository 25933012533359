import type { HLocation } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';

import { Layout } from '../components/layout';
import type { TSanityModule } from '../components/sanity-modules';
import { SanityModulesSM } from '../components/sanity-modules-sm';
import { SEO } from '../components/seo';
import type { ISeo } from '../types';
import { HeroHomeUpgrades } from '../components/modules/hero-home-upgrades';
import { ContactSectionSM } from '../components/modules/contact-section-sm';
import { HeadingWithCtaSM } from '../components/modules/heading-with-cta-sm';
import { CopyWithImageHomeUpgrades } from '../components/modules/copy-with-image-home-upgrades';
import Checkmark from '../images/checkmark.svg';
import { GoogleMapNormal } from '../components/modules/google-map-normal';
import Carousel from 'nuka-carousel';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import InnerHTML from 'dangerously-set-html-content';

const html = `<div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/30YWPsE2Dep4CibaKvfw8H6AMeSUwocARRti5enPOwnfUOgAWeUTkitBzASAqHjk7"><script src="https://webforms.pipedrive.com/f/loader"></script></div>`;
const testhtml = `<script type="text/javascript" src="https://buildertrend.net/leads/contactforms/js/btClientContactForm.js"></script> 
    <iframe src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=85661" scrolling="no" id="btIframe" style="background:transparent;border:0px;margin:0 auto;width:100%;"></iframe>`;

interface HomeUpgradesPageProps {
  data: {
    sanityHomePage: {
      modules: TSanityModule[];
      seo: ISeo;
    };
  };
  location: HLocation;
}

function FinishedProjectsCarousel() {
  return (
    <div className="bg-dark">
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:max-w-screen-2xl sm:px-6 lg:px-8 lg:py-24">
        <h2 className="mb-24 text-4xl font-semibold text-center text-light">
          Finished Projects
        </h2>
        <Carousel
          wrapAround={true}
          slidesToShow={1}
          renderCenterLeftControls={({ previousSlide }) => null}
          renderCenterRightControls={({ nextSlide }) => null}
          defaultControlsConfig={{
            pagingDotsStyle: {
              fill: 'red',
            },
            pagingDotsClassName: 'paging-dot',
          }}
        >
          <StaticImage
            src="../images/home-upgrades1.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/home-upgrades2.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/home-upgrades3.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/home-upgrades4-plus.jpg"
            alt=""
            className="flex-1"
          />
          <StaticImage
            src="../images/home-upgrades5.jpg"
            alt=""
            className="flex-1"
          />
        </Carousel>
      </div>
    </div>
  );
}

function BulletPoints() {
  return (
    <div className="w-full px-4 py-12 mx-auto max-w-prose lg:pt-6 lg:pb-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
      <div className="text-dark">
        <h4 className="font-semibold uppercase heading-red">Our range of</h4>
        <h2 className="text-4xl font-semibold">Home Upgrade Solutions</h2>
        {/* <img src={Checkmark} alt="" className="inline-block w-5 h-5" /> */}
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <ul className="mt-6 ml-6">
              <li className="checkmarkLi">
                Install or renovate your existing outdoor area with a new deck,
                pergola, or veranda in your choice of timber and staining
              </li>
              <li className="mt-6 checkmarkLi">
                Upgrade an unsightly or cramped bathroom and give it an inviting
                new look and functionality
              </li>
              <li className="mt-6 checkmarkLi">
                Renovate the inside of your home by creating open plan areas or
                larger rooms – subject to existing load bearing structures
              </li>
              <li className="mt-6 checkmarkLi">
                Make your kitchen the pride of the home with new appliances,
                benchtops, cupboards, and layouts
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/2">
            <ul className="mt-6 ml-6">
              <li className="checkmarkLi">
                Extend your home by using spare back or frontyard space, or
                explore the possibility of extending upward (STCA)
              </li>
              <li className="mt-6 checkmarkLi">
                We can add privacy screens, pool fences, wooden fences,
                trellises and more
              </li>
              <li className="mt-6 checkmarkLi">
                Get a quality result every time from our team of qualified and
                experienced tradespeople
              </li>
              <li className="mt-6 checkmarkLi">
                Get your FREE QUOTE on all types of home upgrades from one of
                our friendly team members – enquire below
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function HomeUpgradesPage({
  data,
  location,
}: HomeUpgradesPageProps): JSX.Element {
  const { modules, seo } = data.sanityHomePage;
  return (
    <>
      <SEO
        title="Home Upgrades"
        image={seo.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <HeroHomeUpgrades />
        <div className="relative px-24 py-12 mx-auto -mt-0 text-black bg-white shadow-xl lg:-mt-32">
          <div className="mx-auto ">
            <h2 className="text-2xl font-bold text-center text-black md:text-4xl">
              Get in touch for your
              <br />
              FREE QUOTE{' '}
            </h2>
            <InnerHTML html={testhtml} className="mt-6" />
          </div>
        </div>
        <CopyWithImageHomeUpgrades />
        <BulletPoints />
        <FinishedProjectsCarousel />
        <HeadingWithCtaSM />
        <SanityModulesSM modules={modules} />
        {/* ^ this is only for the services grid */}
        <HeadingWithCtaSM />
        {/* <ContactSectionSM /> */}
        <GoogleMapNormal />
      </Layout>
    </>
  );
}

export const query = graphql`
  query HomeUpgradesPageQuery {
    sanityHomePage(_id: { eq: "homePage" }) {
      modules {
        ... on SanityHero {
          ...SanityHeroParts
        }
        ... on SanityCopyWithImage {
          ...SanityCopyWithImageParts
        }
        ... on SanityServicesGrid {
          ...SanityServicesGridParts
        }
        ... on SanityHeadingWithCta {
          ...SanityHeadingWithCtaParts
        }
        ... on SanityFrequentlyAskedQuestions {
          ...SanityFrequentlyAskedQuestionsParts
        }
        ... on SanityContactForm {
          ...SanityContactFormParts
        }
        ... on SanityGoogleMap {
          ...SanityGoogleMapParts
        }
      }
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
    }
  }
`;

export default HomeUpgradesPage;
