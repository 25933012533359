import BlockContent from '@sanity/block-content-to-react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as React from 'react';

import type { ISanityCopy } from '../../fragments/sanity-copy-parts';
import type { ISanityCopyWithImage } from '../../fragments/sanity-copy-with-image-parts';
import type { ISanityFigure } from '../../fragments/sanity-figure-parts';
import type { TColourScheme } from '../../types';
import { classNames } from '../../utils/classnames';
import {
  BG_COLOUR_MAP,
  PROSE_COLOUR_MAP,
  TEXT_COLOUR_MAP,
} from '../../utils/object-dictionaries';
import { ButtonLink } from '../button-link';
import { ContactInfo } from '../contact-info';

interface CopyWithImageProps {
  copyWithImage: ISanityCopyWithImage;
}

function CopyWithImageHomeUpgrades(): JSX.Element {
  return (
    <div className={classNames(BG_COLOUR_MAP['light'])}>
      <div className="w-full px-4 py-12 mx-auto max-w-prose lg:py-24 lg:max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="grid items-center gap-8 lg:grid-cols-2">
          <Figure />
          <Copy />
        </div>
      </div>
    </div>
  );
}

interface FigureProps {
  module: ISanityFigure;
}

function Figure(): JSX.Element {
  return (
    <div className="relative order-last lg:order-none">
      <div
        className="relative overflow-hidden"
        style={{ paddingBottom: '100%' }}
      >
        <div className="absolute inset-0 flex w-full h-full">
          <StaticImage
            src="../../images/home-upgrades-figure-new2.jpg"
            alt=""
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
}

interface CopyProps {
  module: ISanityCopy;
  colourScheme: TColourScheme;
}

function Copy(): JSX.Element {
  return (
    <div className="pb-5 lg:py-12">
      <div
        className={classNames(
          'prose',
          TEXT_COLOUR_MAP['light'],
          PROSE_COLOUR_MAP['light']
        )}
      >
        <h2>Home Upgrades</h2>
        Upgrade your home with a new look, room to move or store, or give it a
        whole new life with Koori Carpentry’s range of home upgrades. We can
        take on all types of home upgrade jobs in Port Macquarie, as simple as
        installing a new veranda, building a deck, renovating a kitchen or
        bathroom, or a combination of renovations to give your entire home a new
        lease on life.
        <br />
        <br />
        Koori Carpentry give you the flexibility to make your home authentically
        yours through a quality upgrade by experienced tradespeople right here
        in Port Macquarie. Add value, add liveability, and add comfort with a
        great home upgrade package.
      </div>

      <p className="mt-5">
        <Link
          to={'/contact-us/'}
          className="inline-block px-6 py-2 text-base font-semibold tracking-wider text-white uppercase bg-primary"
        >
          Contact Us
        </Link>
      </p>
    </div>
  );
}

export { CopyWithImageHomeUpgrades };
export type { ISanityCopyWithImage };
